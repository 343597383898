import { IValidationRule } from "../constants/ConfigTypes";
import { ValidationResult } from "../utils/validators";

export enum EFormFieldType {
  input = "input",
  phone = "phone",
  oneTimeCode = "oneTimeCode",
  radio = "radio",
  dictionary = "dictionary",
  textarea = "textarea",
  files = "files",
}

export type TFormField = {
  id: string;
  type: EFormFieldType;
  options?: { labelTrKey: string; value: string }[];
  headerTrKey: string;
  dictionary?: string;
  groupBy?: string;
  allowedSignsRegex?: string;
  validationKey: string;
  validationMessagesTrKeys?: string;
  validator?: (value: string, config: IValidationRule) => ValidationResult;
  link?: string;
};
