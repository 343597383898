import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { TypoBodyRegular, TypoH1, TypoSubheader } from "../components/UIComponents/Typography";
import { InfoBox } from "../components/UIComponents";
import { t } from "../intl";
import { summaryConfigFields } from "../configs/summaryConfig";
import { Button } from "../components/UIComponents";
import fileImg from "../assets/file.svg";
import { useFormData } from "../providers/FormDataContext";
import { IFormGlobalConfig } from "../constants/ConfigTypes";
import { Services } from "../services/Services";

interface ISummaryProps {
  formGlobalConfig?: IFormGlobalConfig;
}

type TDictLabels = { topic: string | undefined; category: string | undefined };

export const Summary = ({ formGlobalConfig }: ISummaryProps) => {
  useEffect(() => {
    Services.IntegrationService.allowUnload();
  }, []);

  const { uploadResponse, formUserDataState } = useFormData();
  const { contact } = formUserDataState || {};
  const { form: formDataResponse, files: filesDataResponse } = uploadResponse;

  const { dictionaries } = formGlobalConfig || {};
  const getDictLabels = () => {
    const { topic: topicKey, category: categoryKey } = formDataResponse;
    const dictLabels: TDictLabels = { topic: "", category: "" };

    dictLabels.topic = dictionaries?.topics.find((topicDict) => topicDict.key === topicKey)?.value;

    dictLabels.category = dictionaries?.categories[topicKey]?.find(
      (categoryDict) => categoryDict.key === categoryKey
    )?.value;

    return dictLabels;
  };
  const dictLabels = getDictLabels();

  return (
    <Container>
      <Title>{t("summary.sentToVerification.header")}</Title>
      <InfoBox
        contentTrKey={`summary.proofTransmissionDocuments.infoBox.${
          contact.email ? "email" : "phone"
        }`}
        iconColor="gray60"
        iconName="alert-circle"
      />
      <FieldContainer>
        {summaryConfigFields.map((el) => {
          if (el.id === "files") {
            return (
              <Field key={el.id}>
                <FieldLabel>{t(el.labelTrKey)}</FieldLabel>
                {filesDataResponse.accepted.map((file, fileIndex) => {
                  return (
                    <Document key={`${file.filename}-${fileIndex}`}>
                      <img src={fileImg} alt="file" />
                      <DocumentName>{file.originalFilename}</DocumentName>
                    </Document>
                  );
                })}
              </Field>
            );
          }

          if (!formDataResponse[el.id]) return null;

          return (
            <Field key={el.id}>
              <FieldLabel>{t(el.labelTrKey)}</FieldLabel>
              <FieldValue>
                {el.id === "topic" || el.id === "category"
                  ? dictLabels[el.id]
                  : formDataResponse[el.id]}
              </FieldValue>
            </Field>
          );
        })}
      </FieldContainer>
      <ReturnButton
        textTrKey="common.buttons.returnToHome"
        onClick={Services.IntegrationService.navigateToPru}
      />
    </Container>
  );
};

const DocumentName = styled(TypoSubheader)`
  margin-left: 4px;
  word-break: break-word;
`;

const Document = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReturnButton = styled(Button)`
  min-width: 100%;
  background-color: ${({ theme }) => theme.colors.grayDark};
`;

const FieldValue = styled(TypoSubheader)`
  color: ${({ theme }) => theme.colors.gray100};
  word-break: break-word;
  white-space: pre-line;
`;

const FieldLabel = styled(TypoBodyRegular)`
  color: ${({ theme }) => theme.colors.gray90};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 8px;
`;

const Field = styled.div`
  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray40}`};
  }
  padding: 16px 0;
  &:first-child {
    padding-top: 0;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 36px 0;
`;

const Title = styled(TypoH1)`
  margin-bottom: 36px;
`;
