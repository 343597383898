import { Route, Routes as Switch } from "react-router-dom";
import React, { FC } from "react";
import { Main } from "./pages/Main";
import { Sandbox } from "./pages/Sandbox";

export const Routes: FC = () => (
  <div>
    <Switch>
      <Route path="/" element={<Main />} />
      <Route path="/sandbox" element={<Sandbox />} />
    </Switch>
  </div>
);
