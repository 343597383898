import { IValidationRule } from "../constants/ConfigTypes";

export interface ValidationResult {
  isValid: boolean;
  message: string | null;
}

const matchesRegExp = (value: string, pattern: string): boolean => {
  const regExp = new RegExp(pattern, "u");
  return regExp.test(value);
};

export const basicRegexValidator = (
  value: string,
  config: IValidationRule,
  message: string
): ValidationResult => {
  let validationInitialStatus = {
    isValid: true,
    message: message,
  };
  if (config.isRequired && !value) {
    validationInitialStatus.isValid = false;
    validationInitialStatus.message = "validationResults.message.emptyField";
  } else if (!matchesRegExp(value, config.regExp)) {
    validationInitialStatus.isValid = false;
  }

  return validationInitialStatus;
};

export const requiredValidator = (
  value: string,
  config: IValidationRule,
  message: string
): ValidationResult => {
  if (config.isRequired && (value === null || value.trim() === "")) {
    return {
      isValid: false,
      message: message,
    };
  }

  return {
    isValid: true,
    message: null,
  };
};

export const filesValidator = (files: File[], config: IValidationRule): ValidationResult => {
  if (config.isRequired && files.length === 0) {
    return { isValid: false, message: "validationResults.message.files.required" };
  }

  if (files.length > 0) {
    if (files.length > config.arraySize) {
      return { isValid: false, message: "validationResults.message.files.numberOfFiles" };
    }

    if (files.reduce((sum, currentFile) => sum + currentFile.size, 0) > config.binarySize) {
      return { isValid: false, message: "validationResults.message.files.filesSize" };
    }
  }

  return { isValid: true, message: null };
};
