import React, { FC } from "react";
import { createIntl, RawIntlProvider } from "react-intl";
import { plMessages } from "./translations/pl";

export let t = (id: string, values?: any) => "preparing translations...";

interface PreparedIntlProviderProps {
  messages?: Record<string, string>;
}

export const PreparedIntlProvider: FC<PreparedIntlProviderProps> = ({
  messages = plMessages,
  children,
}) => {
  const intl = createIntl({ locale: "pl-PL", messages });

  t = (id: string, values?: any) => {
    if (intl.messages[id]) return intl.formatMessage({ id }, values);
    else return id;
  };

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};
