import React, { useEffect, useState } from "react";
import { ErrorPage } from "./ErrorPage";
import styled from "styled-components/macro";
import { EAppStep } from "../constants/appStep";
import { Summary } from "./Summary";
import { AuthorizationForm } from "./AuthorizationForm";
import { DocumentsForm } from "./DocumentsForm";
import { Services } from "../services/Services";
import { IFormGlobalConfig } from "../constants/ConfigTypes";
import { Loader } from "../components/UIComponents";
import { useAppData } from "../providers/AppDataContext";
import { useWrappedService } from "../utils/useWrappedService";
import { isJsonResponse } from "../services/ApiService";

export const Main = () => {
  useEffect(() => {
    Services.IntegrationService.preventUnload();
  }, []);

  const { appStep, loadingCount } = useAppData();
  const [formGlobalConfig, setFormGlobalConfig] = useState<IFormGlobalConfig>();
  const docsService = useWrappedService(Services.DocsService);

  useEffect(() => {
    docsService.getConfig().then((res) => {
      if (!isJsonResponse(res, "dictionaries")) return;
      setFormGlobalConfig(res);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderContent = (appStep: EAppStep) => {
    switch (appStep) {
      case EAppStep.authorizationForm:
        return <AuthorizationForm formGlobalConfig={formGlobalConfig} />;
      case EAppStep.summary:
        return <Summary formGlobalConfig={formGlobalConfig} />;
      case EAppStep.form:
        return <DocumentsForm formGlobalConfig={formGlobalConfig} />;
      case EAppStep.error:
        return <ErrorPage />;
    }
  };

  return (
    <Wrapper>
      <MainContainer>{renderContent(appStep)}</MainContainer>
      {loadingCount > 0 && <Loader />}
    </Wrapper>
  );
};

const MainContainer = styled.div`
  max-width: calc(612px + 2 * 16px);
  margin: 0 auto;
  padding: 0 16px;

  @media print {
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 80px;
`;
