import React from "react";
import styled from "styled-components/macro";
import LoaderSvg from "../../assets/loader.svg";

interface LoaderProps {
  className?: string;
}

export const Loader: React.FC<LoaderProps> = (props) => (
  <Wrapper className={`${props.className} loader`}>
    <Spinner src={LoaderSvg} size={2} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 11;

  &:before {
    content: "";
    z-index: -1;
    opacity: 0.5;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const Spinner = styled.img<{ size: number }>`
  width: ${({ size }) => size * 64}px;
  animation: rotate cubic-bezier(0.1, 0.3, 0.8, 0.1) 1s infinite;
  position: relative;
  z-index: 12;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
