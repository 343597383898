import React, { FC, RefObject } from "react";
import { CSSObject as SerializeCSSObject } from "@emotion/serialize";
import BaseSelect, {
  ContainerProps,
  ControlProps,
  GroupTypeBase,
  IndicatorProps,
  OptionProps,
} from "react-select";
import { t } from "../../intl";
import styled, { CSSObject } from "styled-components/macro";
import { defaultTheme } from "../../theme/defaultTheme";
import { Icon } from "./Icon";
import { FocusEventHandler } from "react-select/src/types";
import { ValidationInfo } from "./ValidationInfo";

export interface SelectProps {
  className?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  value: string | string[] | number | undefined;
  options: any[];
  disabled?: boolean;
  isInvalid?: boolean;
  onFocus?: FocusEventHandler;
  customStylesExtension?: any;
  placeholder?: string;
  hasDarkBackground?: boolean;
  hideValidationInfo?: boolean;
  validationMessagesTrKeys?: any[];
  selectRef?: RefObject<BaseSelect>;
  id?: string;
}

export const Select: FC<SelectProps> = (props) => {
  const {
    className,
    value,
    onChange,
    disabled,
    options,
    isInvalid,
    onBlur,
    onFocus,
    customStylesExtension,
    placeholder,
    hasDarkBackground,
    hideValidationInfo,
    validationMessagesTrKeys,
    selectRef,
    id,
  } = props;

  const preparedOptions = options?.map((option: any) => ({
    value: option.value,
    label: t(option.labelTrKey) ?? option.label,
  }));
  const selectedOption = preparedOptions?.find((option) => option.value === value) || "";
  const DropdownIndicator = (props: IndicatorProps<any, any>) => (
    <StyledIcon name={props.selectProps.menuIsOpen ? "chevron-up" : "chevron-down"} size={20} />
  );
  return (
    <Wrapper className={className}>
      <BaseSelect
        id={id}
        value={selectedOption}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        onBlur={onBlur}
        components={{ DropdownIndicator }}
        placeholder={placeholder && t(placeholder)}
        ref={selectRef}
        isDisabled={disabled}
        styles={customStyles(!!isInvalid, customStylesExtension)}
        options={preparedOptions}
        onFocus={onFocus}
        blurInputOnSelect={false}
        filterOption={(option, rawInput) =>
          option.label.toLowerCase().includes(rawInput.toLowerCase())
        }
        noOptionsMessage={() => t("common.select.noOptions")}
      />
      {hideValidationInfo !== true && (
        <ValidationInfo
          withIcon={hasDarkBackground}
          textColor={hasDarkBackground ? "white" : "error"}
          validations={validationMessagesTrKeys}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const StyledIcon = styled(Icon)`
  pointer-events: none;
`;
const customStyles = (isInvalid: boolean, customStylesExtension?: any) => {
  return {
    placeholder: (provided: CSSObject) => ({
      ...provided,
      color: defaultTheme.colors.gray80,
    }),
    container: (
      provided: CSSObject,
      state: ContainerProps<any, any, GroupTypeBase<any>>
    ): CSSObject => ({
      ...provided,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      pointerEvents: "initial",
      color: defaultTheme.colors.gray100,
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "20px",

      "::-moz-scrollbar-button, ::-webkit-scrollbar-button": {
        width: "0px",
      },

      ...customStylesExtension?.container,
    }),
    control: (provided: CSSObject, state: ControlProps<any, any, GroupTypeBase<any>>) => ({
      width: "100%",
      background: state.isDisabled ? defaultTheme.colors.gray10 : defaultTheme.colors.white,
      display: "flex",
      border: 0,
      justifyContent: "space-evenly",
      padding: "16px 12px",

      ...(() => {
        if (state.isFocused)
          return { boxShadow: `inset 0px 0px 0px 2px ${defaultTheme.colors.gray100}` };
        if (isInvalid) return { boxShadow: `inset 0px 0px 0px 2px ${defaultTheme.colors.error}` };
        return { boxShadow: `inset 0px 0px 0px 1px ${defaultTheme.colors.gray30}` };
      })(),

      ...(!state.isFocused && !state.isDisabled
        ? {
            "&:hover": {
              boxShadow: `inset 0px 0px 0px 1px ${defaultTheme.colors.primary80}`,
            },
          }
        : {}),

      ...customStylesExtension?.control,
    }),
    valueContainer: (provided: CSSObject) => ({
      ...provided,
      justifyContent: "left",
      paddingRight: 0,
      height: "20px",
      padding: 0,
      display: "-webkit-box",
      webkitBoxPack: "start",
      ...customStylesExtension?.valueContainer,
    }),
    singleValue: (provided: CSSObject) => ({
      ...provided,
      margin: "unset",
      ...customStylesExtension?.singleValue,
    }),
    input: (provided: SerializeCSSObject) => ({
      ...provided,
      padding: "unset",
      margin: "unset",
      ...customStylesExtension?.input,
    }),
    indicatorsContainer: (provided: CSSObject) => ({
      ...provided,
      padding: "unset",
      height: "20px",
      width: "20px",
      alignSelf: "center",
      ...customStylesExtension?.indicatorsContainer,
    }),
    indicatorSeparator: () => ({ ...customStylesExtension?.indicatorSeparator }),
    menu: (provided: CSSObject) => ({
      ...provided,
      marginTop: "-2px",
      boxShadow: defaultTheme.shadows.elevation5,
      background: defaultTheme.colors.white,
      zIndex: 12,
      border: `2px solid ${defaultTheme.colors.gray100}`,
      borderRadius: "unset",
      ...customStylesExtension?.menu,
    }),
    option: (provided: CSSObject, state: OptionProps<any, any, GroupTypeBase<any>>) => ({
      padding: "12px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      textAlign: "left",
      backgroundColor: defaultTheme.colors.white,
      borderTop: `solid 1px ${defaultTheme.colors.gray40}`,

      "&:first-of-type": {
        borderTop: "none",
      },

      "&:hover": {
        backgroundColor: defaultTheme.colors.primary10,
        borderColor: defaultTheme.colors.primary80,

        "& + div": {
          borderColor: defaultTheme.colors.primary80,
        },
      },

      ...(state.isSelected
        ? {
            "&&&": {
              color: defaultTheme.colors.white,
              backgroundColor: defaultTheme.colors.primary100,
              borderTop: "none",
            },

            ":hover": {
              color: defaultTheme.colors.white,
              backgroundColor: defaultTheme.colors.primary100,
            },
          }
        : {}),

      ...customStylesExtension?.option,
    }),
    menuList: (provided: CSSObject) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,

      "::-webkit-scrollbar": {
        width: "8px",
        height: "0px",
      },

      "::-webkit-scrollbar-track": {
        backgroundColor: defaultTheme.colors.gray10,
      },

      "::-webkit-scrollbar-thumb": {
        backgroundColor: defaultTheme.colors.gray40,
      },

      ...customStylesExtension?.menuList,
    }),
  };
};
