import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Button } from "./UIComponents";
import { TypoBodyLineRegular, TypoSmallRegular } from "./UIComponents/Typography";
import { t } from "../intl";
import { useFormData } from "../providers/FormDataContext";

interface IDropzoneComponent {
  onDragOver: () => void;
}
export const DropzoneComponent = ({ onDragOver }: IDropzoneComponent) => {
  const { addFiles, formUserDataState } = useFormData();
  const onDrop = useCallback((acceptedFiles) => {
    addFiles(acceptedFiles);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (formUserDataState.files.length < 1) return;
    onDragOver();
  }, [formUserDataState.files]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DropContainer {...getRootProps()}>
      <input {...getInputProps()} />
      <InfoContainer>
        <DropZoneTitle>{t("documentsForm.form.documents.dropArea.addFiles")}</DropZoneTitle>
        <DropZoneDesc>{t("documentsForm.form.documents.dropArea.addFilesDesc")}</DropZoneDesc>
      </InfoContainer>
      <Button
        rightIconName="upload"
        buttonType="primaryBlack"
        textTrKey="documentsForm.form.documents.dropArea.button.add"
      />
    </DropContainer>
  );
};
const DropZoneDesc = styled(TypoSmallRegular)`
  color: ${({ theme }) => theme.colors.black80};
  margin-bottom: 24px;
`;
const DropZoneTitle = styled(TypoBodyLineRegular)`
  color: ${({ theme }) => theme.colors.black80};
  margin-bottom: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const DropContainer = styled.div`
  min-width: 100%;
  border: 2px dashed ${({ theme }) => theme.colors.gray40};
  padding: 32px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
