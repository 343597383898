import React, { FC } from "react";
import { Input, InputProps } from "./Input";
import { LabelQuestion, LabelQuestionProps } from "./LabelQuestion";

interface NumericInputProps extends InputProps {
  labelProps: LabelQuestionProps;
}
export const NumericInput: FC<NumericInputProps> = React.memo((props) => {
  const { value, onFocus, onBlur, labelProps } = props;

  return (
    <>
      {labelProps.labelTrKey && <LabelQuestion {...labelProps} />}
      <Input
        {...props}
        value={value}
        onFocus={() => {
          onFocus && onFocus();
        }}
        onBlur={(val) => {
          onBlur && onBlur(val);
        }}
      />
    </>
  );
});
