import React from "react";
import styled, { css } from "styled-components/macro";
import { t } from "../../intl";
import { ValidationInfo, ValidationInfoSize } from "./ValidationInfo";

export interface RadioListProps {
  disabled?: boolean;
  isInvalid?: boolean;
  value?: string | number | string[];
  options: {
    value: string | number | string[];
    labelTrKey: string;
    isInvalid?: boolean;
  }[];
  className?: string;
  onChange: (value: string | number | string[]) => void;
  onBlur?: () => void;
  id?: string;
  validationMessagesTrKeys?: any[];
  inline?: boolean;
}

export const RadioList: React.FC<RadioListProps> = (props) => {
  return (
    <Wrapper className={props.className}>
      <OptionsWrapper inline={props.inline}>
        {props.options?.map((option) => {
          const isOptionSelected = option.value === props.value;
          return (
            <Option
              key={option.value as string}
              onClick={() => !props.disabled && !isOptionSelected && props.onChange(option.value)}
              disabled={props.disabled}
            >
              <RadioMark
                selected={isOptionSelected}
                disabled={props.disabled}
                isInvalid={option.isInvalid || props.isInvalid}
              />
              <OptionText disabled={props.disabled}>{t(option.labelTrKey)}</OptionText>
            </Option>
          );
        })}
      </OptionsWrapper>
      <ValidationWrapper>
        {props.isInvalid && (
          <ValidationInfo
            size={ValidationInfoSize.body}
            withIcon
            validations={props.validationMessagesTrKeys}
          />
        )}
      </ValidationWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ValidationWrapper = styled.div`
  min-height: 20px;
`;

const OptionsWrapper = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  gap: 12px;

  ${({ theme }) => theme.media.belowSm} {
    flex-direction: column;
  }
`;

const Option = styled.div<{
  disabled?: boolean;
}>`
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  padding: 14px 12px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
`;

const RadioMark = styled.div<{ selected: boolean; disabled?: boolean; isInvalid?: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ${({ theme, disabled, selected, isInvalid }) => {
    if (!selected) {
      switch (true) {
        case disabled:
          return css`
            background-color: ${theme.colors.gray10};
            border-color: ${theme.colors.gray60};
          `;

        case isInvalid:
          return css`
            background-color: ${theme.colors.primary05};
            border-color: ${theme.colors.error};

            &:hover {
              background-color: ${theme.colors.primary20};
              border-color: ${theme.colors.primary100};
            }
          `;

        default:
          return css`
            background-color: ${theme.colors.primary05};
            border-color: ${theme.colors.primary80};

            &:hover {
              background-color: ${theme.colors.primary20};
              border-color: ${theme.colors.primary100};
            }
          `;
      }
    }

    switch (true) {
      case disabled:
        return css`
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.gray60};
          border-width: 7px;
        `;

      case isInvalid:
        return css`
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.error};
          border-width: 7px;
        `;

      default:
        return css`
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.gray100};
          border-width: 7px;
        `;
    }
  }}
`;

const OptionText = styled.div<{ disabled?: boolean }>`
  flex: 1;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.gray100};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.gray60};
    `};
`;
