import React from "react";
import styled from "styled-components/macro";
import { LabelQuestion, LabelQuestionProps } from "./LabelQuestion";
import { RadioList, RadioListProps } from "./RadioList";

export interface RadioListFormElementProps {
  radioListProps: RadioListProps;
  labelProps: LabelQuestionProps;
}

export const RadioListFormElement = ({ radioListProps, labelProps }: RadioListFormElementProps) => {
  return (
    <Wrapper>
      {labelProps.labelTrKey && <LabelQuestion {...labelProps} />}
      <RadioList {...radioListProps} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
