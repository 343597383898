export const plMessages = {
  "common.pru.url": "https://pru.pl",
  "common.buttons.cancel": "Anuluj",
  "common.buttons.returnToHome": "Wróć do strony głównej",
  "common.navigateToPru.confirmText":
    "Czy na pewno chcesz anulować wysyłkę dokumentów? Wprowadzone dane nie zostaną zapisane.",
  "common.select.noOptions": "Brak opcji",
  "common.select.choose": "Wybierz",

  "authorizationForm.infoBox.acceptRules.text":
    "Podając dane wyrażasz zgodę na wykonanie usługi Bezpiecznego Przekazywania Dokumentów. Szczegółowe informacje dotyczące celu przetwarzania i usługi zawarte są w {privacyPolicyLink} oraz {serviceRulesLink}.\n\nAdministratorem danych osobowych będzie Prudential International Assurance plc Spółka Akcyjna oddział w Polsce.",
  "authorizationForm.infoBox.acceptRules.privacyPolicy.label": "Polityce Prywatności",
  "authorizationForm.infoBox.acceptRules.privacyPolicy.url":
    "https://www.pru.pl/polityka-prywatnosci/",
  "authorizationForm.infoBox.acceptRules.serviceRules.label":
    "Regulaminie świadczenia usług drogą elektroniczną",
  "authorizationForm.infoBox.acceptRules.serviceRules.url":
    "https://www.pru.pl/regulamin-swiadczenia-uslug-droga-elektroniczna/",
  "authorizationForm.form.receiveAuthorizationCode.title":
    "W jaki sposób chcesz otrzymać kod autoryzacyjny?",
  "authorizationForm.form.receiveAuthorizationCode.options.sms": "SMS",
  "authorizationForm.form.receiveAuthorizationCode.options.email": "e-mail",
  "authorizationForm.form.phoneNumber.title": "Numer telefonu",
  "authorizationForm.form.phoneNumber.prefix": "+48",
  "authorizationForm.form.emailAddress.title": "Adres e-mail",
  "authorizationForm.form.buttons.sendCode": "Wyślij kod i przejdź dalej",

  "documentsForm.form.code.phone.title": "Kod jednorazowy wysłany w wiadomości SMS",
  "documentsForm.form.code.email.title": "Kod jednorazowy wysłany na adres e-mail",
  "documentsForm.form.oneTimeCode.sendAgain": "Wyślij ponownie",
  "documentsForm.form.oneTimeCode.authorizationCode": "kod autoryzacyjny",
  "documentsForm.form.firstName.title": "Imię",
  "documentsForm.form.lastName.title": "Nazwisko",
  "documentsForm.form.policyNo.title": "Numer wniosku lub polisy",
  "documentsForm.form.topic.title": "Czego dotyczy sprawa?",
  "documentsForm.form.category.title": "Kategoria",
  "documentsForm.form.description.title": "Informacje dodatkowe i dane kontaktowe",
  "documentsForm.form.description.optionalText": "(pole opcjonalne)",
  "documentsForm.form.documents.title": "Dokumenty do przekazania",
  "documentsForm.form.documents.infoBox.passwordAttention":
    "Pamiętaj, pliki zabezpieczone hasłem nie zostaną przez nas przyjęte. Nie musisz zabezpieczać dokumentów hasłem, proces ich wysyłki jest w pełni bezpieczny",
  "documentsForm.form.documents.dropArea.addFiles":
    'Przeciągnij i upuść plik tutaj lub kliknij "Dodaj".',
  "documentsForm.form.documents.dropArea.addFilesDesc":
    "Dozwolone formaty to: jpg, jpeg, doc, docx, xls, xlsx, pdf, png, txt. Możesz wgrać do 10 plików, których łączny rozmiar nie przekracza 25MB.",
  "documentsForm.form.documents.dropArea.button.add": "Dodaj",
  "documentsForm.form.documents.fileList.removeFile": "Usuń",
  "documentsForm.form.infoBox.invalid":
    "W formularzu znajdują się nieprawidłowo wypełnione pola.\nPopraw je i spróbuj ponownie.",
  "documentsForm.form.buttons.sendDocuments": "Przekaż dokumenty",

  "summary.sentToVerification.header": "Twoje dokumenty zostały przesłane do weryfikacji",
  "summary.proofTransmissionDocuments.infoBox.phone":
    "Potwierdzenie otrzymania dokumentów wyślemy na podany numer telefonu. Poniżej znajduje się podsumowanie wprowadzonych danych.",
  "summary.proofTransmissionDocuments.infoBox.email":
    "Potwierdzenie otrzymania dokumentów wyślemy na adres e-mail. Poniżej znajduje się podsumowanie wprowadzonych danych.",
  "summary.userData.firstName.label": "Imię",
  "summary.userData.lastName.label": "Nazwisko",
  "summary.userData.policyNo.label": "Numer wniosku lub polisy",
  "summary.userData.topic.label": "Czego dotyczy sprawa?",
  "summary.userData.category.label": "Kategoria",
  "summary.userData.description.label": "Informacje dodatkowe i dane kontaktowe",
  "summary.userData.documents.label": "Dokumenty do przekazania",

  "errorPage.unexpectedError.header": "Wystąpił nieoczekiwany błąd",
  "errorPage.unexpectedError.desc": "Usługa tymczasowo niedostępna - spróbuj później.",
  "errorPage.unexpectedError.upload.desc":
    "Nie udało się wysłać przekazanych przez Ciebie danych. Odczekaj chwilę i uzupełnij formularz ponownie",

  "validationResults.message.wrongNumber": "Niepoprawny numer telefonu",
  "validationResults.message.wrongFormat": "Niepoprawny format",
  "validationResults.message.wrongEmail": "Niepoprawny email",
  "validationResults.message.emptyField": "Pole nie może być puste",
  "validationResults.message.files.wrongFormat": "Niepoprawny format pliku",
  "validationResults.message.files.wrongName": "Niepoprawna nazwa pliku",
  "validationResults.message.files.filesSize": "Przekroczony maksymalny rozmiar wszystkich plików.",
  "validationResults.message.files.required": "Brak załączonych plików, dodaj załączniki.",
  "validationResults.message.files.numberOfFiles": "Przekroczona maksymalna liczba plików.",
};
