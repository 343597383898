import { Dispatch, SetStateAction } from "react";
import { IFormDataState } from "../providers/FormDataContext";

export const addFilesToState = (
  files: File[],
  setFormUserDataState: Dispatch<SetStateAction<IFormDataState>>
) => {
  setFormUserDataState((formUserDataState) => {
    return {
      ...formUserDataState,
      files: [...formUserDataState.files, ...files],
    };
  });
};

export const removeFileFromState = (
  index: number,
  setFormUserDataState: Dispatch<SetStateAction<IFormDataState>>
) => {
  setFormUserDataState((formUserDataState) => {
    const remainingFiles = [...formUserDataState.files];
    remainingFiles.splice(index, 1);

    return {
      ...formUserDataState,
      files: remainingFiles,
    };
  });
};
