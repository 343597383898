import React, { useEffect, useState } from "react";
import { FormTypesTest, useFormData } from "../providers/FormDataContext";
import { documentsEmailFormConfig, documentsPhoneFormConfig } from "../configs/documentsFormConfig";
import { InfoBox, InputFormElement, MainButtons, OneTimeCode } from "../components/UIComponents";
import { SelectFormElement } from "../components/UIComponents/SelectFormElement";
import { IFormGlobalConfig } from "../constants/ConfigTypes";
import { EAppStep } from "../constants/appStep";
import { TextAreaForm } from "../components/UIComponents/TextAreaFormElement";
import { DropzoneFilesFormElements } from "../components/UIComponents/DropzoneFilesFormElements";
import styled from "styled-components/macro";
import { ValidationResult } from "../utils/validators";
import { Services } from "../services/Services";
import { useAppData } from "../providers/AppDataContext";
import { EFormFieldType, TFormField } from "../models/formField";
import { useWrappedService } from "../utils/useWrappedService";
import { isJsonResponse } from "../services/ApiService";

interface IDocumentsForm {
  formGlobalConfig?: IFormGlobalConfig;
}

export const DocumentsForm = ({ formGlobalConfig }: IDocumentsForm) => {
  const {
    formUserDataState,
    setFormUserDataState,
    validationStatuses,
    setValidationStatuses,
    setUploadResponse,
  } = useFormData();
  const { setAppStep } = useAppData();
  const { validationRules, dictionaries } = formGlobalConfig || {};
  const [isFormValid, setIsFormValid] = useState(false);
  const configForm = !!formUserDataState.contact.phone
    ? documentsPhoneFormConfig
    : documentsEmailFormConfig;
  const docsService = useWrappedService(Services.DocsService);

  useEffect(() => {
    Services.IntegrationService.scrollToTop();
  }, []);

  useEffect(() => {
    if (!validationStatuses) return;
    setIsFormValid(!!Object.values(validationStatuses).find((el) => !el.isValid));
  }, [validationStatuses]);

  const prepareTopicCategorySelectOptions = (fieldId: string) => {
    if (fieldId === "topic") {
      return dictionaries?.topics.map((topic) => ({ value: topic.key, label: topic.value }));
    } else if (fieldId === "category" && formUserDataState.topic) {
      return dictionaries?.categories[formUserDataState.topic].map((category) => ({
        value: category.key,
        label: category.value,
      }));
    }
    return [];
  };

  const onChangeValue = (fieldId: string, value: string) => {
    setFormUserDataState({ ...formUserDataState, [fieldId]: value });
  };

  const checkFormIsValid = () => {
    if (!configForm) return;

    const validationsSendStatus = configForm
      .filter((el) => !!el.validationKey)
      .map((el) => validateField(el.id));

    return validationsSendStatus.some((validationResult) => {
      if (!validationResult) return false;
      return Object.values(validationResult).some((result) => !result.isValid);
    });
  };

  const setValidationResult = (fieldId: string, validationResult: ValidationResult) => {
    const newValidationStatus = { [fieldId]: validationResult };
    setValidationStatuses((prevState) => ({ ...prevState, ...newValidationStatus }));
    return newValidationStatus;
  };

  const submitForm = () => {
    if (checkFormIsValid()) return;

    docsService.uploadDocuments(formUserDataState).then((res) => {
      if (!isJsonResponse(res, "form")) return;
      setUploadResponse(res);
      setAppStep(EAppStep.summary);
    });
  };

  const validateField = (fieldId: string) => {
    const fieldConfig = configForm.find((config) => config.id === fieldId);
    if (fieldConfig?.validationKey && fieldConfig.validator) {
      const validationRule = validationRules?.find(
        (rule) => rule.fieldId === fieldConfig.validationKey
      );
      if (!validationRule?.isRequired) return;

      const newValidationStatus = setValidationResult(
        fieldId,
        fieldConfig.validator(formUserDataState[fieldId as keyof FormTypesTest], validationRule)
      );

      return newValidationStatus;
    }
  };

  const renderField = (field: TFormField, questionIndex: number) => {
    const fieldValidation = validationStatuses?.[field.id] || { isValid: true, message: null };
    switch (field.type) {
      case EFormFieldType.oneTimeCode:
        return (
          <FormField key={field.id}>
            <OneTimeCode
              inputProps={{
                value: formUserDataState[field.id as keyof FormTypesTest],
                onChange: (value) => onChangeValue(field.id, value),
                onBlur: () => validateField(field.id),
                allowedSignsRegex: field.allowedSignsRegex,
                isInvalid: !fieldValidation.isValid,
                validationMessagesTrKeys: fieldValidation.message
                  ? [{ trKey: fieldValidation.message }]
                  : [],
              }}
              labelProps={{
                questionIndex: questionIndex,
                labelTrKey: field.headerTrKey,
              }}
              setCodeValidationResult={(validationResult: ValidationResult) =>
                setValidationResult(field.id, validationResult)
              }
            />
          </FormField>
        );
      case EFormFieldType.input:
        return (
          <FormField key={field.id}>
            <InputFormElement
              inputProps={{
                value: formUserDataState[field.id as keyof FormTypesTest],
                onChange: (value) => onChangeValue(field.id, value),
                onBlur: () => validateField(field.id),
                allowedSignsRegex: field.allowedSignsRegex,
                isInvalid: !fieldValidation.isValid,
                validationMessagesTrKeys: fieldValidation.message
                  ? [{ trKey: fieldValidation.message }]
                  : [],
              }}
              labelProps={{
                questionIndex: questionIndex,
                labelTrKey: field.headerTrKey,
              }}
            />
          </FormField>
        );
      case EFormFieldType.textarea:
        return (
          <FormField key={field.id}>
            <TextAreaForm
              key={field.id}
              inputProps={{
                value: formUserDataState[field.id as keyof FormTypesTest],
                onChange: (value) => onChangeValue(field.id, value),
              }}
              labelProps={{
                questionIndex: questionIndex,
                labelTrKey: field.headerTrKey,
              }}
            />
          </FormField>
        );
      case EFormFieldType.dictionary:
        const options = prepareTopicCategorySelectOptions(field.id);
        return (
          <FormField key={field.id}>
            <SelectFormElement
              key={field.id}
              selectProps={{
                id: field.id,
                onBlur: () => validateField(field.id),
                value: options?.find(
                  (el: any) => el.value === formUserDataState[field.id as keyof FormTypesTest]
                )?.value,
                options: options || [],
                isInvalid: !fieldValidation.isValid,
                validationMessagesTrKeys: fieldValidation.message
                  ? [{ trKey: fieldValidation.message }]
                  : [],
                onChange: (value) => onChangeValue(field.id, value),
                disabled: field.id === "category" && !formUserDataState.topic,
              }}
              labelProps={{
                questionIndex: questionIndex,
                labelTrKey: field.headerTrKey,
              }}
            />
          </FormField>
        );
      case EFormFieldType.files:
        return (
          <FormField key={field.id}>
            <DropzoneFilesFormElements
              key={field.id}
              setValidationStatuses={setValidationStatuses}
              validationStatuses={validationStatuses}
              onDragOver={() => validateField(field.id)}
              validationRules={validationRules || []}
              labelProps={{
                questionIndex: questionIndex,
                labelTrKey: field.headerTrKey,
              }}
            />
          </FormField>
        );
    }
  };
  return (
    <>
      {configForm.map((el, index) => {
        let questionIndex = index + 1;
        return renderField(el, questionIndex);
      })}
      {isFormValid && (
        <ErrorContainer>
          <InfoBox
            contentTrKey="documentsForm.form.infoBox.invalid"
            iconColor="secondaryDark"
            iconName="alert-triangle"
            errorBg
          />
        </ErrorContainer>
      )}
      <MainButtons
        textTrKey="documentsForm.form.buttons.sendDocuments"
        isSubForm
        disabled={isFormValid}
        onBack={Services.IntegrationService.navigateToPruAfterConfirm}
        onClick={() => submitForm()}
      />
    </>
  );
};

const ErrorContainer = styled.div`
  margin-top: 24px;
`;

const FormField = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
`;
