import React, { useEffect, useState } from "react";
import { IFormGlobalConfig } from "../constants/ConfigTypes";
import styled from "styled-components/macro";
import { authorizationConfig } from "../configs/authorizationFormConfig";
import { InfoBox, RadioListFormElement } from "../components/UIComponents";
import { NumericInput } from "../components/UIComponents";
import { InputFormElement } from "../components/UIComponents";
import { ValidationResult } from "../utils/validators";
import { EAppStep } from "../constants/appStep";
import { t } from "../intl";
import { MainButtons } from "../components/UIComponents";
import { useFormData } from "../providers/FormDataContext";
import { Services } from "../services/Services";
import { TAuthTokenRequest } from "../services/CasService";
import { useAppData } from "../providers/AppDataContext";
import { EFormFieldType, TFormField } from "../models/formField";
import { useWrappedService } from "../utils/useWrappedService";

interface IAuthorizationForm {
  formGlobalConfig?: IFormGlobalConfig;
}

interface IFormState {
  [key: string]: string;
}

export interface IValidationStatus {
  [key: string]: ValidationResult;
}
export const AuthorizationForm = ({ formGlobalConfig }: IAuthorizationForm) => {
  const {
    formUserDataState,
    setFormUserDataState,
    validationStatuses,
    setValidationStatuses,
    authorizationType,
    setAuthorizationType,
  } = useFormData();
  const { setAppStep } = useAppData();
  const { validationRules } = formGlobalConfig || {};
  const initialFormState = { phone: "", email: "" };
  const [formState, setFormState] = useState<IFormState>(initialFormState);
  const [isFormValid, setIsFormValid] = useState(false);
  const casService = useWrappedService(Services.CasService);

  useEffect(() => {
    if (!validationStatuses) return;
    setIsFormValid(!!Object.values(validationStatuses).find((el) => !el.isValid));
  }, [validationStatuses]);

  const onChangeAuthorizationType = (value: "phone" | "email") => {
    setValidationStatuses({});
    setAuthorizationType(value);
    setFormState(() => ({ ...initialFormState }));
  };

  const onChangeValue = (fieldId: string, value: string) => {
    setFormState((prevState) => ({ ...prevState, [fieldId]: value }));
  };

  const validateField = (fieldId: string) => {
    const fieldConfig = authorizationConfig.find((config) => config.id === fieldId);
    if (fieldConfig?.validationKey && fieldConfig.validator) {
      const validationRule = validationRules?.find(
        (rule) => rule.fieldId === fieldConfig.validationKey
      );
      if (!validationRule?.isRequired) return;

      const newValidationStatus = {
        ...validationStatuses,
        [fieldId]: fieldConfig.validator(formState[fieldId], validationRule),
      };
      setValidationStatuses(newValidationStatus);
      return newValidationStatus;
    }
  };

  const filteredFields = authorizationConfig.filter((c) =>
    ["authorizationType", authorizationType === "phone" ? "phone" : "email"].includes(c.id)
  );

  const checkFormIsValid = () => {
    if (!filteredFields) return;

    const validationsSendStatus = filteredFields
      .filter((el) => !!el.validationKey)
      .map((el) => validateField(el.id));

    return validationsSendStatus.some((validationResult) => {
      if (!validationResult) return false;
      return Object.values(validationResult).some((result) => !result.isValid);
    });
  };

  const renderField = (field: TFormField, questionIndex: number) => {
    const fieldValidation = validationStatuses?.[field.id] || { isValid: true, message: null };
    switch (field.type) {
      case EFormFieldType.radio:
        return (
          <RadioListFormElement
            key={field.id}
            radioListProps={{
              id: field.id,
              value: authorizationType,
              options: field.options || [],
              inline: true,
              onChange: (value: string | number | string[]) =>
                onChangeAuthorizationType(value === "phone" ? "phone" : "email"),
            }}
            labelProps={{
              questionIndex: questionIndex,
              labelTrKey: field.headerTrKey,
            }}
          />
        );
      case EFormFieldType.phone:
        return (
          <NumericInput
            key={field.id}
            value={formState[field.id]}
            onChange={(value) => onChangeValue(field.id, value)}
            onBlur={() => validateField(field.id)}
            onFocus={Services.IntegrationService.recordUserInteraction}
            filledDesc={t("authorizationForm.form.phoneNumber.prefix")}
            allowedSignsRegex={"^\\d{0,9}$"}
            labelProps={{
              questionIndex: questionIndex,
              labelTrKey: field.headerTrKey,
            }}
            isInvalid={!fieldValidation.isValid}
            validationMessagesTrKeys={
              fieldValidation.message ? [{ trKey: fieldValidation.message }] : []
            }
          />
        );
      case EFormFieldType.input:
        return (
          <InputFormElement
            key={field.id}
            inputProps={{
              value: formState[field.id],
              onChange: (value) => onChangeValue(field.id, value),
              onBlur: () => validateField(field.id),
              onFocus: Services.IntegrationService.recordUserInteraction,
              isInvalid: !fieldValidation.isValid,
              validationMessagesTrKeys: fieldValidation.message
                ? [{ trKey: fieldValidation.message }]
                : [],
            }}
            labelProps={{
              questionIndex: questionIndex,
              labelTrKey: field.headerTrKey,
            }}
          />
        );
      default:
        return null;
    }
  };

  const prepareContactMethodData = (formState: IFormState): TAuthTokenRequest => {
    if (authorizationType === "phone") return { phone: formState.phone };
    return { email: formState.email };
  };

  const submitForm = () => {
    if (checkFormIsValid()) return;

    setFormUserDataState({
      ...formUserDataState,
      contact: { phone: formState.phone, email: formState.email },
    });

    casService.generateAndSendAuthToken(prepareContactMethodData(formState)).then((res) => {
      if (!res) return;
      setAppStep(EAppStep.form);
    });
  };

  return (
    <>
      <InfoBox
        iconName="info"
        iconColor="gray60"
        contentTrKey={{
          id: "authorizationForm.infoBox.acceptRules.text",
          values: {
            privacyPolicyLink: <ExternalLink trKey="privacyPolicy" />,
            serviceRulesLink: <ExternalLink trKey="serviceRules" />,
          },
        }}
      />
      <FormContainer>
        {filteredFields?.map((el, index) => {
          let questionIndex = index + 1;
          return renderField(el, questionIndex);
        })}
      </FormContainer>
      <MainButtons
        textTrKey="authorizationForm.form.buttons.sendCode"
        isSubForm
        onBack={Services.IntegrationService.navigateToPruAfterConfirm}
        disabled={isFormValid}
        onClick={submitForm}
      />
    </>
  );
};

const FormContainer = styled.div`
  margin: 60px 0 8px;
`;

const ExternalLink = ({ trKey }: { trKey: "privacyPolicy" | "serviceRules" }) => (
  <a
    href={t(`authorizationForm.infoBox.acceptRules.${trKey}.url`)}
    target="_blank"
    rel="noopener noreferrer"
  >
    {t(`authorizationForm.infoBox.acceptRules.${trKey}.label`)}
  </a>
);
