import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { addFilesToState, removeFileFromState } from "../utils/fileOperations";
import { IValidationStatus } from "../pages/AuthorizationForm";
import { IUploadResponse } from "../models/uploadResponse";

export interface FormTypesTest {
  code: string;
}

export interface IFormDataState {
  firstname: string;
  lastname: string;
  policyNo: string;
  code: string;
  topic: string;
  category: string;
  description: string;
  files: File[];
  contact: { phone: string; email: string };
}

const initialFormData: IFormDataState = {
  firstname: "",
  lastname: "",
  code: "",
  policyNo: "",
  topic: "",
  category: "",
  description: "",
  files: [],
  contact: { phone: "", email: "" },
};

const initialUploadResponse: IUploadResponse = {
  form: {
    firstname: "",
    lastname: "",
    policyNo: "",
    topic: "",
    category: "",
    description: "",
  },
  files: {
    accepted: [],
  },
};

export type TAuthorizationType = "phone" | "email";

interface IFormDataContext {
  authorizationType: TAuthorizationType;
  setAuthorizationType: Dispatch<SetStateAction<TAuthorizationType>>;
  formUserDataState: IFormDataState;
  setFormUserDataState: Dispatch<SetStateAction<IFormDataState>>;
  uploadResponse: IUploadResponse;
  setUploadResponse: Dispatch<SetStateAction<IUploadResponse>>;
  validationStatuses: IValidationStatus | undefined;
  setValidationStatuses: Dispatch<SetStateAction<IValidationStatus | undefined>>;
  addFiles: (files: File[]) => void;
  removeFile: (index: number) => void;
}

const defaultValue: IFormDataContext = {
  authorizationType: "phone",
  setAuthorizationType: () => {},
  formUserDataState: initialFormData,
  setFormUserDataState: () => {},
  uploadResponse: initialUploadResponse,
  setUploadResponse: () => {},
  validationStatuses: {},
  setValidationStatuses: () => {},
  addFiles: () => {},
  removeFile: () => {},
};

const FormDataContext = createContext<IFormDataContext>(defaultValue);

export const FormDataProvider = ({ children }: { children: ReactNode }) => {
  const [formUserDataState, setFormUserDataState] = useState<IFormDataState>(initialFormData);
  const [uploadResponse, setUploadResponse] = useState<IUploadResponse>(initialUploadResponse);
  const [validationStatuses, setValidationStatuses] = useState<IValidationStatus>();
  const [authorizationType, setAuthorizationType] = useState<TAuthorizationType>("phone");

  const contextValue: IFormDataContext = {
    authorizationType,
    setAuthorizationType,
    formUserDataState,
    setFormUserDataState,
    uploadResponse,
    setUploadResponse,
    validationStatuses,
    setValidationStatuses,
    addFiles: (files) => addFilesToState(files, setFormUserDataState),
    removeFile: (index) => removeFileFromState(index, setFormUserDataState),
  };

  return <FormDataContext.Provider value={contextValue}>{children}</FormDataContext.Provider>;
};

export const useFormData = (): IFormDataContext => {
  return useContext(FormDataContext);
};
