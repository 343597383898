import React, { useState } from "react";
import styled, { css } from "styled-components";

export interface ITextAreaProps {
  value: string;
  disabled?: boolean;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement> & (() => void);
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  className?: string;
}

export const TextArea = React.memo((props: ITextAreaProps) => {
  const {
    value,
    disabled,
    className,
    isInvalid,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    maxLength = 1000,
  } = props;
  const [characterCount, setCharacterCount] = useState(0);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
    setCharacterCount(e.target.value.length);
  };

  return (
    <Wrapper>
      <StyledTextArea
        value={value || ""}
        disabled={disabled}
        isInvalid={isInvalid}
        maxLength={maxLength}
        onChange={handleChange}
        onBlur={(e) => onBlur?.(e.target.value)}
        onFocus={onFocus}
        placeholder={!disabled ? placeholder : ""}
        className={className}
      />
      <CharacterCount>
        {characterCount}/{maxLength}
      </CharacterCount>
    </Wrapper>
  );
});

const Wrapper = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.gray100};
`;

const StyledTextArea = styled.textarea<{ isInvalid?: boolean }>`
  width: 100%;
  min-height: 156px;
  display: block;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray100};
  -webkit-text-fill-color: ${({ theme }) => theme.colors.gray100};
  background-clip: padding-box;
  position: relative;
  border: 0;
  resize: none;
  padding: 12px;
  box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.gray40};
  border-radius: 0;
  appearance: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray60};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.gray60};
    opacity: 1;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray80};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.gray80};
    background: ${({ theme }) => theme.colors.gray10};
    cursor: not-allowed;
    opacity: 1;
  }

  ${({ isInvalid, theme }) =>
    isInvalid &&
    css`
      box-shadow: inset 0px 0px 0px 2px ${theme.colors.error};
    `}

  &:hover:not(:focus):not(:disabled) {
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.primary80};
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.colors.gray100};
    outline: 0;
  }
`;

const CharacterCount = styled.div`
  margin-top: 4px;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray60};
`;
