import { t } from "../intl";

export interface ComplexMessage {
  source: string;
  type: "confirmRedirect" | "preventUnload" | "allowUnload" | "scrollToTop";
}

export const sourceName = "doc-upload-fe";

export class IntegrationService {
  private userInteractionDetected = false;

  private preventUnloadRequested = false;

  private getComplexMessage = (type: ComplexMessage["type"]) =>
    ({
      source: sourceName,
      type,
    } as ComplexMessage);

  private postMessageToParent = (type: ComplexMessage["type"]) => {
    window.parent.postMessage(this.getComplexMessage(type), "*");
  };

  private applyPreventUpload = () => {
    this.postMessageToParent("preventUnload");
  };

  recordUserInteraction = () => {
    if (this.preventUnloadRequested && !this.userInteractionDetected) {
      this.applyPreventUpload();
    }
    this.userInteractionDetected = true;
  };

  navigateToPru = () => (window.parent.location.href = t("common.pru.url"));

  navigateToPruAfterConfirm = () => {
    const useParent = true; // TODO: decide whether handling should occur on the parent side or not
    if (useParent) {
      this.postMessageToParent("confirmRedirect");
    } else {
      if (window.confirm(t("common.navigateToPru.confirmText"))) {
        this.postMessageToParent("allowUnload");
        setTimeout(() => {
          this.navigateToPru();
        }, 0);
      }
    }
  };

  preventUnload = () => {
    if (this.userInteractionDetected) {
      this.applyPreventUpload();
    }
    this.preventUnloadRequested = true;
  };

  allowUnload = () => {
    this.postMessageToParent("allowUnload");
  };

  scrollToTop = () => {
    this.postMessageToParent("scrollToTop");
  };
}
