import React from "react";
import styled, { css } from "styled-components/macro";
import { Icon } from "./Icon";
import { DefaultThemeColor } from "../../theme/defaultTheme";
import { t } from "../../intl";

interface IInfoBoxProps {
  contentTrKey: string | { id: string; values?: any };
  iconName: string;
  iconColor: DefaultThemeColor;
  errorBg?: boolean;
  className?: string;
}
export const InfoBox = (props: IInfoBoxProps) => {
  const { contentTrKey, iconColor, iconName, errorBg = false, className } = props;

  return (
    <Wrapper errorBg={errorBg} className={className}>
      <Icon name={iconName} size={18} color={iconColor} />
      <Description>
        {typeof contentTrKey === "string"
          ? t(contentTrKey)
          : t(contentTrKey.id, contentTrKey.values)}
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ errorBg: boolean }>`
  padding: 16px;
  display: flex;
  align-items: start;
  page-break-inside: avoid;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  ${({ theme, errorBg }) => css`
    border: 1px solid ${theme.colors.gray40};
    background-color: ${theme.colors[errorBg ? "errorBg" : "gray10"]};
    color: ${theme.colors.gray100};
  `}
`;

const Description = styled.div`
  margin-left: 16px;
  white-space: pre-line;

  a {
    text-decoration: underline;

    ${({ theme }) => css`
      color: ${theme.colors.gray100};
      transition: 150ms ease-in-out filter;

      &:hover {
        filter: brightness(20%);
      }
    `}
  }
`;
