import React, { FC } from "react";
import styled from "styled-components/macro";
import { t } from "../../intl";
import { DefaultThemeColor } from "../../theme/defaultTheme";
import { Icon } from "./Icon";
import { TypoBodyBold, TypoSmallBold } from "./Typography";

export enum ValidationInfoSize {
  small = "small",
  body = "body",
}

interface ValidationInfoProps {
  className?: string;
  size?: keyof typeof ValidationInfoSize;
  withIcon?: boolean;
  textColor?: DefaultThemeColor;
  validations?: {
    trKey: string;
    values?: any;
  }[];
}

export const ValidationInfo: FC<ValidationInfoProps> = (props) => {
  const {
    TypoComponent,
    iconSize,
    iconSpacingRight,
    iconSpacingTop,
    componentSpacingTop,
    iconName,
    iconColor,
  } = sizeDependentData[props.size!];

  return (
    <Wrapper className={props.className} componentSpacingTop={componentSpacingTop}>
      {!!props.validations?.length && props.withIcon && (
        <StyledIcon
          name={iconName}
          size={iconSize}
          iconSpacingRight={iconSpacingRight}
          iconSpacingTop={iconSpacingTop}
          color={iconColor as DefaultThemeColor}
        />
      )}
      <TypoComponent textColor={props.textColor!}>
        {props.validations?.map((validation, index) => (
          <div key={index}>{t(validation.trKey, validation.values)}</div>
        ))}
      </TypoComponent>
    </Wrapper>
  );
};

ValidationInfo.defaultProps = {
  size: ValidationInfoSize.small,
  textColor: "error",
};

const Wrapper = styled.div<{ componentSpacingTop: number }>`
  margin-top: ${({ componentSpacingTop }) => componentSpacingTop}px;
  display: flex;
  align-items: flex-start;
  min-height: 20px;
`;

const SmallValidationInfo = styled(TypoSmallBold)<{ textColor: DefaultThemeColor }>`
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  line-height: 18px;
  min-height: 18px;
`;

const BodyValidationInfo = styled(TypoBodyBold)<{ textColor: DefaultThemeColor }>`
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  min-height: 20px;
`;

const StyledIcon = styled(Icon)<{ iconSpacingRight: number; iconSpacingTop: number }>`
  margin-right: ${({ iconSpacingRight }) => iconSpacingRight}px;
  margin-top: ${({ iconSpacingTop }) => iconSpacingTop}px;
`;

const sizeDependentData = {
  [ValidationInfoSize.small]: {
    TypoComponent: SmallValidationInfo,
    iconSize: 18,
    iconSpacingRight: 4,
    iconSpacingTop: -1,
    componentSpacingTop: 4,
    iconName: "fill-alert-fill",
    iconColor: undefined,
  },
  [ValidationInfoSize.body]: {
    TypoComponent: BodyValidationInfo,
    iconSize: 16,
    iconSpacingRight: 8,
    iconSpacingTop: 2,
    componentSpacingTop: 0,
    iconName: "alert-triangle",
    iconColor: "error",
  },
};
