import React, { RefObject, useRef } from "react";
import styled from "styled-components/macro";
import { LabelQuestion, LabelQuestionProps } from "./LabelQuestion";
import { Select, SelectProps } from "./Select";
import BaseSelect from "react-select";

export interface SelectFormElementProps {
  selectProps: SelectProps;
  labelProps: LabelQuestionProps;
}

export const SelectFormElement = ({ selectProps, labelProps }: SelectFormElementProps) => {
  const selectRef = useRef(null) as RefObject<BaseSelect>;
  return (
    <Wrapper>
      {labelProps && (
        <LabelQuestion {...labelProps} handleLabelClick={() => selectRef?.current!.focus()} />
      )}
      <Select placeholder="common.select.choose" {...selectProps} selectRef={selectRef} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
