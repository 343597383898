import React from "react";
import styled from "styled-components/macro";
import { Button } from "./Button";

interface MainButtonsProps {
  textTrKey: string;
  isSubForm?: boolean;
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const MainButtons: React.FC<MainButtonsProps> = ({
  onBack,
  onClick,
  disabled,
  textTrKey,
  isSubForm,
}) => (
  <StyledButtons isSubForm={isSubForm}>
    {onBack && <Button buttonType="secondary" textTrKey="common.buttons.cancel" onClick={onBack} />}
    <Button buttonType="primaryBlack" textTrKey={textTrKey} onClick={onClick} disabled={disabled} />
  </StyledButtons>
);

const StyledButtons = styled.div<{ isSubForm?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ isSubForm }) => (isSubForm ? "padding: 24px 0;" : "margin-top: 32px;")}
  gap: 12px;

  ${({ isSubForm }) => (isSubForm ? "white-space: nowrap;" : "")}
  & > button {
    width: 100%;
    max-width: 100%;
  }

  @media print {
    display: none;
  }

  ${({ theme }) => theme.media.belowSm} {
    flex-direction: column-reverse;
  }
`;
