type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type FunctionEntries<T> = {
  [K in keyof T]: [K, T[K] extends (...args: infer A) => unknown ? T[K] : never];
}[keyof T][];

type NonFunctionEntries<T> = {
  [K in keyof T]: [K, T[K] extends (...args: infer A) => unknown ? never : T[K]];
}[keyof T][];

export const getObjectEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export const getClassEntries = <T extends object>(obj: T) => ({
  properties: getObjectEntries(obj).filter(
    ([_, v]) => !(v instanceof Function)
  ) as NonFunctionEntries<T>,
  methods: getObjectEntries(obj).filter(([_, v]) => v instanceof Function) as FunctionEntries<T>,
});
