import React, { useEffect } from "react";
import styled from "styled-components/macro";
import alertImg from "../assets/alert.svg";
import { TypoBodyRegular, TypoH1 } from "../components/UIComponents/Typography";
import { Button } from "../components/UIComponents";
import { t } from "../intl";
import { useAppData } from "../providers/AppDataContext";
import { Services } from "../services/Services";

export const ErrorPage = () => {
  const { errorMessage } = useAppData();

  useEffect(() => {
    Services.IntegrationService.scrollToTop();
  }, []);

  return (
    <Container>
      <LogoContainer>
        <img src={alertImg} alt="alert" />
      </LogoContainer>
      <Title>{t("errorPage.unexpectedError.header")}</Title>
      <Description>{errorMessage || t("errorPage.unexpectedError.desc")}</Description>
      <ReturnButton
        textTrKey="common.buttons.returnToHome"
        onClick={() => Services.IntegrationService.navigateToPru()}
      ></ReturnButton>
    </Container>
  );
};

const ReturnButton = styled(Button)`
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.grayDark};
  @media print {
    display: none;
  }
`;

const Description = styled(TypoBodyRegular)`
  padding-bottom: 36px;
`;

const Title = styled(TypoH1)`
  padding: 16px 0 36px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
