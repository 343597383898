import { basicRegexValidator } from "../utils/validators";
import { IValidationRule } from "../constants/ConfigTypes";
import { EFormFieldType, TFormField } from "../models/formField";

export const authorizationConfig: TFormField[] = [
  {
    id: "authorizationType",
    type: EFormFieldType.radio,
    headerTrKey: "authorizationForm.form.receiveAuthorizationCode.title",
    options: [
      {
        labelTrKey: "authorizationForm.form.receiveAuthorizationCode.options.sms",
        value: "phone",
      },
      {
        labelTrKey: "authorizationForm.form.receiveAuthorizationCode.options.email",
        value: "email",
      },
    ],
    validationKey: "",
  },
  {
    id: "phone",
    type: EFormFieldType.phone,
    headerTrKey: "authorizationForm.form.phoneNumber.title",
    link: "authorizationType",
    validationKey: "contact.phone",
    validationMessagesTrKeys: "phone",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongNumber"),
  },
  {
    id: "email",
    type: EFormFieldType.input,
    headerTrKey: "authorizationForm.form.emailAddress.title",
    link: "authorizationType",
    validationKey: "contact.email",
    validationMessagesTrKeys: "email",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongEmail"),
  },
];
