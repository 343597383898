import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components/macro";
import { DropzoneComponent } from "../DropzoneComponent";
import { useFormData } from "../../providers/FormDataContext";
import { DropzoneAddedFiles } from "../DropZoneAddedFiles";
import { LabelQuestion, LabelQuestionProps } from "./LabelQuestion";
import { IValidationRule } from "../../constants/ConfigTypes";
import { IValidationStatus } from "../../pages/AuthorizationForm";
import { InfoBox } from "./InfoBox";

export interface IDropzoneFilesFormElements {
  labelProps: LabelQuestionProps;
  validationRules: IValidationRule[];
  validationStatuses?: IValidationStatus;
  setValidationStatuses: Dispatch<SetStateAction<IValidationStatus | undefined>>;
  onDragOver: () => void;
}
export const DropzoneFilesFormElements = (props: IDropzoneFilesFormElements) => {
  const { labelProps, validationRules, validationStatuses } = props;
  const { formUserDataState } = useFormData();
  const filesValidationStatus = validationStatuses?.files || null;

  return (
    <>
      {labelProps && <LabelQuestion {...labelProps} />}
      <StyledInfoBox
        contentTrKey="documentsForm.form.documents.infoBox.passwordAttention"
        iconName="info"
        iconColor="gray60"
      />
      <DropzoneComponent onDragOver={props.onDragOver} />
      {!!formUserDataState.files.length && (
        <DropzoneAddedFiles {...props} validationRules={validationRules} />
      )}
      {filesValidationStatus && !filesValidationStatus.isValid && filesValidationStatus.message && (
        <ErrorContainer>
          <InfoBox
            contentTrKey={filesValidationStatus.message}
            iconColor="secondaryDark"
            iconName="alert-triangle"
            errorBg
          />
        </ErrorContainer>
      )}
    </>
  );
};

const StyledInfoBox = styled(InfoBox)`
  margin: 10px 0;
`;

const ErrorContainer = styled.div`
  margin-top: 24px;
`;
