import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Icon } from "./Icon";
import { ValidationInfo } from "./ValidationInfo";
import { TypoBodyBold } from "./Typography";
import { t } from "../../intl";

export enum TextAlign {
  center = "center",
  left = "left",
  right = "right",
}

export interface FixedPlaceholderConfig {
  fixedPlaceholder: string;
  maskPattern: string;
  parseWithSeparators?: boolean;
}

export interface InputProps {
  value: string;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  inputType?: string;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onBlurInternal?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement> & ((e?: any) => void);
  innerRef?: any;
  placeholder?: string;
  allowedSignsRegex?: string;
  disablePasting?: boolean;
  fixedPlaceholderConfig?: FixedPlaceholderConfig;
  textAlign?: keyof typeof TextAlign;
  iconName?: string;
  iconPosition?: "left" | "right";
  filledDesc?: string;
  hasDarkBackground?: boolean;
  id?: string;
  validationMessagesTrKeys?: any[];
  hideValidationInfoWhenValid?: boolean;
}

export const Input: React.FC<InputProps> = React.memo((props) => {
  const {
    value,
    disabled,
    autoFocus,
    className,
    inputType,
    isInvalid,
    onChange,
    onFocus,
    onBlur,
    onBlurInternal,
    innerRef,
    placeholder,
    allowedSignsRegex,
    disablePasting,
    textAlign,
    iconName,
    iconPosition,
    filledDesc,
    hasDarkBackground,
    id,
    validationMessagesTrKeys,
    hideValidationInfoWhenValid,
  } = props;

  const filledDescRef = useRef<HTMLDivElement>(null);
  const [hideCaret, setHideCaret] = useState(false);

  const CONFIG = props.fixedPlaceholderConfig;

  const stripPlaceholderChars = (str: string) => {
    if (!CONFIG) throw new Error("Missing config");

    const placeholderChars = Array.from(new Set(CONFIG.fixedPlaceholder.split("")));
    return str
      .split("")
      .filter((char) => !placeholderChars.includes(char))
      .join("");
  };

  const formatValue = (value = "") => {
    if (!CONFIG) throw new Error("Missing config");

    const valueChars = stripPlaceholderChars(value).split("");
    const result = CONFIG.maskPattern
      .split("")
      .map((char, index) =>
        valueChars.length && char === "x" ? valueChars.shift() : CONFIG.fixedPlaceholder[index]
      )
      .join("");
    return result;
  };

  const [displayedValue, setDisplayedValue] = useState(CONFIG ? formatValue(value) : value);

  useEffect(() => {
    if (!CONFIG) return;
    setDisplayedValue(formatValue(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, CONFIG]);

  const parseValue = (formatedValue: string) => {
    if (!CONFIG) throw new Error("Missing config");

    return formatedValue.split("").reduce((acc, char, index) => {
      const shouldSkip = CONFIG.parseWithSeparators
        ? CONFIG.maskPattern[index] === "x" && char === CONFIG.fixedPlaceholder[index]
        : CONFIG.maskPattern[index] !== "x" || char === CONFIG.fixedPlaceholder[index];
      return shouldSkip ? acc : acc + char;
    }, "");
  };

  const [skipReset, setSkipReset] = useState(false);

  const getMaxIndex = (value: string) => {
    if (!CONFIG) throw new Error("Missing config");
    let counter = stripPlaceholderChars(value).length;
    return CONFIG.maskPattern.split("").reduce((acc, char) => {
      if (counter === 0) return acc;
      if (char === "x") counter--;
      return acc + 1;
    }, 0);
  };

  const resetSelectionRange = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
    if (skipReset) {
      setSkipReset(false);
      return;
    }
    const input = e.target as EventTarget & HTMLInputElement;
    const index = Math.min(input.selectionEnd || 0, getMaxIndex(value));
    setTimeout(() => input.setSelectionRange(index, index), 0);
  };

  const getNextCaretPosition = (input: EventTarget & HTMLInputElement) => {
    let caretOffset = input.selectionEnd || 0;
    if (!CONFIG) return caretOffset;

    const inputValue = input.value;
    const parsed = parseValue(formatValue(inputValue));
    const firstMissIndex = inputValue
      .slice(0, CONFIG.maskPattern.length)
      .split("")
      .findIndex(
        (char, index) =>
          CONFIG.maskPattern[index] !== "x" && char !== CONFIG.fixedPlaceholder[index]
      );
    if (firstMissIndex !== -1 && inputValue.length >= CONFIG.maskPattern.length) {
      let counter = caretOffset - firstMissIndex;
      const maskSlice = CONFIG.maskPattern.slice(firstMissIndex);
      const additionalOffset = maskSlice.split("").reduce((acc, char) => {
        if (counter <= 0) return acc;
        if (char === "x") {
          counter--;
          return acc;
        } else {
          return acc + 1;
        }
      }, 0);
      caretOffset += additionalOffset;
    }

    return Math.min(caretOffset, getMaxIndex(parsed));
  };

  const passedOnBlur = onBlurInternal
    ? onBlurInternal
    : (e: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(e.target.value);

  const preparedRegex = allowedSignsRegex && new RegExp(allowedSignsRegex, "u");

  return (
    <Wrapper>
      <InputWrapper>
        <StyledInput
          id={id}
          value={CONFIG ? displayedValue : value || ""}
          disabled={disabled}
          autoFocus={autoFocus}
          type={inputType}
          isInvalid={isInvalid}
          maxLength={4000}
          hideCaret={hideCaret}
          textAlign={textAlign!}
          onChange={(e) => {
            setHideCaret(true);
            setSkipReset(true);

            const input = e.target;
            const caretPosition = getNextCaretPosition(input);

            const inputValue = e.target.value;
            const nextValue = CONFIG ? parseValue(formatValue(inputValue)) : inputValue;

            if (!preparedRegex || preparedRegex.test(nextValue)) {
              onChange(nextValue);
              setTimeout(() => input.setSelectionRange(caretPosition, caretPosition), 0);
            } else {
              setTimeout(() => input.setSelectionRange(caretPosition - 1, caretPosition - 1), 0);
            }
            setTimeout(() => setHideCaret(false), 0);
          }}
          onSelect={CONFIG && resetSelectionRange}
          onBlur={passedOnBlur}
          onFocus={onFocus}
          autoComplete={String(!disablePasting)}
          onPaste={(e) => {
            if (!disablePasting) return true;
            e.preventDefault();
            return false;
          }}
          onDrop={(e) => {
            if (!disablePasting) return true;
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            if (!CONFIG) return;
            e.preventDefault();
            navigator.clipboard.writeText(value);
          }}
          ref={innerRef}
          className={className}
          placeholder={!disabled && placeholder ? t(placeholder) : ""}
          iconName={iconName}
          iconPosition={iconPosition}
          filledDescWidth={filledDescRef.current?.offsetWidth}
        />
        {iconName && (
          <StyledIcon size={20} name={iconName} color="gray60" iconPosition={iconPosition} />
        )}
        {filledDesc && <FilledDesc ref={filledDescRef}>{t(filledDesc)}</FilledDesc>}
      </InputWrapper>
      {!(hideValidationInfoWhenValid && !isInvalid) && (
        <ValidationWrapper>
          {isInvalid && (
            <ValidationInfo
              withIcon={hasDarkBackground}
              textColor={hasDarkBackground ? "white" : "error"}
              validations={validationMessagesTrKeys}
            />
          )}
        </ValidationWrapper>
      )}
    </Wrapper>
  );
});

Input.defaultProps = {
  inputType: "text",
  textAlign: TextAlign.left,
  iconPosition: "left",
};

const Wrapper = styled.label`
  display: block;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const ValidationWrapper = styled.div`
  min-height: 20px;
  margin-top: 4px;
`;

const StyledInput = styled.input<{
  isInvalid?: boolean;
  hideCaret?: boolean;
  textAlign: keyof typeof TextAlign;
  iconName?: string;
  iconPosition?: "left" | "right";
  filledDescWidth?: number;
}>`
  width: 100%;
  height: 52px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
  box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.gray30};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ theme }) => theme.colors.gray100};
  -webkit-text-fill-color: ${({ theme }) => theme.colors.gray100};
  background-clip: padding-box;
  position: relative;
  padding: 0 12px;
  font-size: 18px;
  ${({ hideCaret }) => hideCaret && "caret-color: transparent;"}
  ${({ iconName, iconPosition }) => iconName && css`padding-${iconPosition}: 44px`};
  ${({ filledDescWidth }) =>
    filledDescWidth &&
    css`
      padding-left: ${filledDescWidth}px;
    `};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray80};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.gray80};
    opacity: 1;
    text-align: left;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray80};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.gray80};
    background: ${({ theme }) => theme.colors.gray10};
    cursor: not-allowed;
    opacity: 1;
  }

  ${({ isInvalid, theme }) =>
    isInvalid &&
    css`
      box-shadow: inset 0px 0px 0px 2px ${theme.colors.error};
    `}
  &:hover:not(:focus):not(:disabled) {
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.primary80};
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.colors.gray100};
    outline: 0;
  }
`;

const StyledIcon = styled(Icon)<{
  iconPosition?: "left" | "right";
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;

  ${({ iconPosition }) =>
    css`
      ${iconPosition}: 12px
    `};
`;

const FilledDesc = styled(TypoBodyBold)`
  color: ${({ theme }) => theme.colors.gray100};
  font-size: 18px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 12px;
  top: 0;
  bottom: 0;
  pointer-events: none;
`;
