import React from "react";
import { t } from "../../intl";
import { Label, LabelProps } from "./Label";

export interface LabelQuestionProps extends LabelProps {
  questionIndex?: number;
  tooltipTrKey?: string;
  required?: boolean;
}

export const LabelQuestion: React.FC<LabelQuestionProps> = (props) => {
  const { required = false, questionIndex, labelTrKey, ...rest } = props || {};

  const text = `${questionIndex ? questionIndex + ". " : ""}${labelTrKey && t(labelTrKey)}`;

  return (
    <Label
      {...rest}
      text={text}
      required={required}
      labelTextStyles={"font-weight: 600; font-size: 18px; line-height: 20px;"}
    />
  );
};
