import { EAppStep } from "../constants/appStep";
import { TAuthorizationType } from "../providers/FormDataContext";

interface IErrorResponse {
  code: string;
  message: string;
}

export function isErrorResponse(response: unknown): response is IErrorResponse {
  return !!(response as IErrorResponse)?.code && !!(response as IErrorResponse)?.message;
}

export enum EErrorCode {
  ipLimitExceed = "1006",
  tokenLimitExceed = "1007",
  invalidPhone = "1008",
  invalidEmail = "1009",
  invalidCredentials = "INVALID_CREDENTIALS",
  invalidCredentialsTriesLimitExceed = "INVALID_CREDENTIALS_TRIES_LIMIT_EXCEEDED",
  tokenExpired = "TOKEN_EXPIRED",
}

type TErrorCodeToFieldIdFnParams = {
  appStep: EAppStep;
  authorizationType: TAuthorizationType;
};

type TErrorCodeToFieldIdFn = (params: TErrorCodeToFieldIdFnParams) => "code" | "phone" | "email";
type TErrorCodeToFnFieldIdMap = Record<string, TErrorCodeToFieldIdFn>;

const limitExceedToFieldIdMapper: TErrorCodeToFieldIdFn = ({ appStep, authorizationType }) => {
  if (appStep === EAppStep.authorizationForm) {
    return authorizationType === "email" ? "email" : "phone";
  }
  return "code";
};

export const errorCodeToFnFieldIdMap: TErrorCodeToFnFieldIdMap = {
  [EErrorCode.ipLimitExceed]: limitExceedToFieldIdMapper,
  [EErrorCode.tokenLimitExceed]: limitExceedToFieldIdMapper,
  [EErrorCode.invalidPhone]: () => "phone",
  [EErrorCode.invalidEmail]: () => "email",
  [EErrorCode.invalidCredentials]: () => "code",
  [EErrorCode.invalidCredentialsTriesLimitExceed]: () => "code",
  [EErrorCode.tokenExpired]: () => "code",
};
