import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ComplexMessage, sourceName } from "../services/IntegrationService";

const paths = {
  self: "/doc-upload",
  dev: "https://public-dev.pre-pru.pl/doc-upload",
  test: "https://public-test.pre-pru.pl/doc-upload",
  uat: "https://public-uat.pre-pru.pl/doc-upload",
  preprod: "https://public-preprod.pre-pru.pl/doc-upload",
  prod: "https://public.pru.pl/doc-upload",
};

const initialHeight = 0;

const confirmText =
  "Czy na pewno chcesz anulować wysyłkę dokumentów? Wprowadzone dane nie zostaną zapisane.";

export const Sandbox = () => {
  const [iFrameHeight, setIFrameHeight] = useState<number>(initialHeight);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<number | ComplexMessage>) => {
      if (event.source === event.target) {
        // self-emitted message: ignore
        console.log("self", event);
        return;
      }

      switch (typeof event.data) {
        case "number":
          // height reporting message: set iframe height
          setIFrameHeight(event.data);
          break;

        case "object":
          // complex message: check if source is "doc-upload-fe"
          if (event.data.source === sourceName) {
            switch (event.data.type) {
              case "confirmRedirect":
                // redirect to pru.pl (but only after confirm)
                if (window.confirm(confirmText)) {
                  window.onbeforeunload = null;
                  window.location.href = "https://pru.pl";
                }
                break;

              case "preventUnload":
                // show prompt before unloading
                window.onbeforeunload = (e: Event) => {
                  e.preventDefault();
                  return true;
                };
                break;

              case "allowUnload":
                // stop showing prompt before unloading (and scroll to top of the iframe)
                window.onbeforeunload = null;
                window.scroll({
                  top: document.getElementById("BPD")?.offsetTop || 0,
                  behavior: "smooth",
                });
                break;

              case "scrollToTop":
                // scroll to top of the iframe
                window.scroll({
                  top: document.getElementById("BPD")?.offsetTop || 0,
                  behavior: "smooth",
                });
                break;

              default:
                // unsupported type: ignore
                console.log(event.data.type, event.data);
                break;
            }
          } else {
            // unsupported source: ignore
            console.log(event.data.source, event.data);
          }
          break;

        default:
          // unsupported message: ignore
          console.log(typeof event.data, event);
          break;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  }, []);
  return (
    <Wrapper>
      <IFrame id="BPD" src={paths.self} frameBorder={0} style={{ height: iFrameHeight }} />
    </Wrapper>
  );
};

const IFrame = styled.iframe`
  width: 100%;
`;

const Wrapper = styled.div`
  background-color: dodgerblue;
  display: flex;
  justify-content: center;
  padding: 650px 0 1400px;
`;
