import React, { FC } from "react";
import { ThemeProvider } from "styled-components/macro";
import { Normalize } from "styled-normalize";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { PreparedIntlProvider } from "./intl";

import { defaultTheme } from "./theme/defaultTheme";
import { GlobalStyle } from "./theme/GlobalStyle";

import { App } from "./App";
import { FormDataProvider } from "./providers/FormDataContext";
import { AppDataProvider } from "./providers/AppDataContext";

export const Providers: FC = () => (
  <ThemeProvider theme={defaultTheme}>
    <div>
      <Normalize />
      <GlobalStyle />
      <PreparedIntlProvider>
        <BrowserRouter basename={`/${process.env.REACT_APP_PREFIX}`}>
          <AppDataProvider>
            <FormDataProvider>
              <App />
            </FormDataProvider>
          </AppDataProvider>
        </BrowserRouter>
      </PreparedIntlProvider>
    </div>
  </ThemeProvider>
);

interface TestProvidersProps {
  mockTranslations?: Record<string, string>;
}

export const TestProviders: FC<TestProvidersProps> = ({ children, mockTranslations }) => (
  <ThemeProvider theme={defaultTheme}>
    <div>
      <Normalize />
      <GlobalStyle />
      <PreparedIntlProvider messages={mockTranslations}>
        <MemoryRouter initialEntries={[`/${process.env.REACT_APP_PREFIX}`]}>
          {children}
        </MemoryRouter>
      </PreparedIntlProvider>
    </div>
  </ThemeProvider>
);
