import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormData } from "../providers/FormDataContext";
import styled from "styled-components/macro";
import { Button, Icon } from "./UIComponents";
import { TypoBodyRegular, TypoSubheader } from "./UIComponents/Typography";
import { IValidationRule } from "../constants/ConfigTypes";
import { t } from "../intl";
import { IValidationStatus } from "../pages/AuthorizationForm";

interface IDropzoneAddedFiles {
  validationRules: IValidationRule[];
  validationStatuses?: IValidationStatus;
  setValidationStatuses: Dispatch<SetStateAction<IValidationStatus | undefined>>;
}

const validateFileExtension = (file: File, rules?: IValidationRule): string | null => {
  if (!rules) return null;
  const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
  if (!rules.extensions.includes(fileExtension)) {
    return file.name;
  }

  return null;
};

const validateFileName = (file: File): string | null => {
  const regex = /^[-_.\s\d\p{L}]+$/u;
  if (!regex.test(file.name)) {
    return file.name;
  }

  return null;
};

export const DropzoneAddedFiles = ({
  validationRules,
  setValidationStatuses,
}: IDropzoneAddedFiles) => {
  const { formUserDataState, removeFile } = useFormData();
  const [invalidFiles, setInvalidFiles] = useState<{ filename: string; errorType: string }[]>([]);

  useEffect(() => {
    const validationRule = validationRules.find((rule) => rule.fieldId === "files");
    const foundInvalidFiles: { filename: string; errorType: string }[] = [];

    formUserDataState.files.forEach((file) => {
      const extensionError = validateFileExtension(file, validationRule);
      const nameError = validateFileName(file);

      if (extensionError !== null) {
        foundInvalidFiles.push({ filename: extensionError, errorType: "extension" });
      }
      if (nameError !== null) {
        foundInvalidFiles.push({ filename: nameError, errorType: "name" });
      }
    });

    if (foundInvalidFiles.length) {
      setValidationStatuses((prevState) => ({
        ...prevState,
        files: { ...(prevState ? prevState.files : { message: "" }), isValid: false },
      }));
    }

    setInvalidFiles(foundInvalidFiles);
  }, [formUserDataState.files, validationRules]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {formUserDataState.files.map((file, fileIndex) => {
        const invalidFile = invalidFiles.find((el) => el.filename === file.name);
        return (
          <FileItem key={`${file.name}-${fileIndex}`}>
            <TitleContainer>
              <Icon
                size={20}
                name={invalidFile ? "file-text" : "file"}
                color={invalidFile ? "error" : "primary100"}
              />
              <div>
                <SubHeaderNameFile invalidFile={!!invalidFile}>{file.name}</SubHeaderNameFile>
                {invalidFile && (
                  <ErrorInfo>
                    {invalidFile && (
                      <ErrorInfo>
                        {invalidFile.errorType === "extension"
                          ? t("validationResults.message.files.wrongFormat")
                          : t("validationResults.message.files.wrongName")}
                      </ErrorInfo>
                    )}
                  </ErrorInfo>
                )}
              </div>
            </TitleContainer>
            <RemoveButton
              onClick={() => removeFile(fileIndex)}
              rightIconName="x-close"
              textTrKey="documentsForm.form.documents.fileList.removeFile"
              buttonSize="sm"
              buttonType="secondary"
            />
          </FileItem>
        );
      })}
    </Container>
  );
};

const ErrorInfo = styled(TypoBodyRegular)`
  color: ${({ theme }) => theme.colors.error};
`;

const SubHeaderNameFile = styled(TypoSubheader)<{ invalidFile: boolean }>`
  color: ${({ theme, invalidFile }) => (invalidFile ? theme.colors.error : theme.colors.gray100)};
  word-break: break-word;
`;

const RemoveButton = styled(Button)`
  min-width: 78px;
  width: 78px;
  height: 36px;
  padding: 8px 12px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 4px;
`;

const FileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray20}; ;
`;

const Container = styled.div`
  margin-top: 32px;
`;
