import React, { FC, useEffect } from "react";
import { Routes } from "./Routes";

export const App: FC = () => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      window.parent.postMessage(entries[0].target.clientHeight, "*");
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return <Routes />;
};
