import { EAppStep } from "../constants/appStep";
import { getClassEntries } from "./objectUtils";
import { useAppData } from "../providers/AppDataContext";
import { useFormData } from "../providers/FormDataContext";
import { CasService } from "../services/CasService";
import { DocsService } from "../services/DocsService";
import { errorCodeToFnFieldIdMap, isErrorResponse } from "./errors";

type TAllServices = CasService | DocsService;

export const useWrappedService = <T extends TAllServices>(service: T) => {
  const { incrementLoadingCount, decrementLoadingCount, setAppStep, appStep, setErrorMessage } =
    useAppData();
  const { setValidationStatuses, authorizationType } = useFormData();

  const { properties, methods } = getClassEntries(service);

  return {
    ...methods.reduce((allClassMethods, [methodName, serviceMethod]) => {
      type P = Parameters<typeof serviceMethod>;
      type R = ReturnType<typeof serviceMethod>;

      return {
        ...allClassMethods,
        [methodName]: (...args: P) => {
          incrementLoadingCount();

          return (serviceMethod as (...args: P) => R extends Promise<unknown> ? R : never)(...args)
            .then((res) => {
              if (!isErrorResponse(res)) return res;

              const fieldId = errorCodeToFnFieldIdMap[res.code]?.({
                appStep,
                authorizationType,
              });
              if (!fieldId) throw Error(res?.message);

              setValidationStatuses((prev) => ({
                ...prev,
                [fieldId]: { isValid: false, message: res.message },
              }));
            })
            .catch(async (err) => {
              setAppStep(EAppStep.error);
              if ((err || {}).message) setErrorMessage(err.message);
            })
            .finally(() => decrementLoadingCount());
        },
      };
    }, {} as T),
    ...properties.reduce((allClassProperties, [propertyName, serviceProperty]) => {
      return { ...allClassProperties, [propertyName]: serviceProperty };
    }, {} as T),
  };
};
