import { InputProps } from "./Input";
import React from "react";
import styled from "styled-components/macro";
import { LabelQuestion, LabelQuestionProps } from "./LabelQuestion";
import { TextArea } from "./TextArea";

export interface InputFormProps {
  inputProps: InputProps;
  labelProps?: LabelQuestionProps;
}

export const TextAreaForm = ({ inputProps, labelProps }: InputFormProps) => {
  return (
    <Wrapper>
      {labelProps && <LabelQuestion required {...labelProps} />}
      <TextArea {...inputProps} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;
