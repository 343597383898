import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { EAppStep } from "../constants/appStep";

interface IAppDataContext {
  appStep: EAppStep;
  setAppStep: Dispatch<SetStateAction<EAppStep>>;
  errorMessage?: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  loadingCount: number;
  incrementLoadingCount: () => void;
  decrementLoadingCount: () => void;
}

const defaultValue = {
  appStep: EAppStep.authorizationForm,
  setAppStep: () => {},
  setErrorMessage: () => {},
  loadingCount: 0,
  incrementLoadingCount: () => {},
  decrementLoadingCount: () => {},
};

const AppDataContext = createContext<IAppDataContext>(defaultValue);

export const AppDataProvider = ({ children }: { children: ReactNode }) => {
  const [appStep, setAppStep] = useState(EAppStep.authorizationForm);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingCount, setLoadingCount] = useState(0);
  const contextValue: IAppDataContext = {
    appStep,
    setAppStep,
    errorMessage,
    setErrorMessage,
    loadingCount,
    incrementLoadingCount: () => setLoadingCount(loadingCount + 1),
    decrementLoadingCount: () => setLoadingCount(loadingCount < 1 ? 0 : loadingCount - 1),
  };

  return <AppDataContext.Provider value={contextValue}>{children}</AppDataContext.Provider>;
};

export const useAppData = () => {
  return useContext(AppDataContext);
};
