import React, { MouseEvent, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Input, InputProps } from "./Input";
import { LabelQuestion, LabelQuestionProps } from "./LabelQuestion";
import { t } from "../../intl";
import { useFormData } from "../../providers/FormDataContext";
import { Services } from "../../services/Services";
import { ValidationResult } from "../../utils/validators";
import { useWrappedService } from "../../utils/useWrappedService";

const TIME_TO_SHOW_INFO_BOX = 60_000;

export interface IOneTimeCodeProps {
  inputProps: InputProps;
  labelProps?: LabelQuestionProps;
  setCodeValidationResult: (validationResult: ValidationResult) => void;
}

export const OneTimeCode = (props: IOneTimeCodeProps) => {
  const { inputProps, labelProps, setCodeValidationResult } = props;
  const { formUserDataState } = useFormData();
  const [showInfoBox, setShowInfoBox] = useState(false);
  const casService = useWrappedService(Services.CasService);
  const sendAuthCodeAgain = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setCodeValidationResult({ isValid: true, message: null });
    const contactData = formUserDataState.contact;
    const data = contactData.phone ? { phone: contactData.phone } : { email: contactData.email };

    casService.generateAndSendAuthToken(data);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowInfoBox(true), TIME_TO_SHOW_INFO_BOX);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Wrapper>
      {labelProps && <LabelQuestion {...labelProps} />}
      {showInfoBox && (
        <InfoBox>
          <StyledLink onClick={sendAuthCodeAgain}>
            {t("documentsForm.form.oneTimeCode.sendAgain")}
          </StyledLink>{" "}
          {t("documentsForm.form.oneTimeCode.authorizationCode")}
        </InfoBox>
      )}
      <Input {...inputProps} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;

const InfoBox = styled.div`
  background-color: ${({ theme }) => theme.colors.primary20};
  padding: 10px;
  text-align: center;
  font-weight: 700;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primary100};
  text-decoration: underline;
  cursor: pointer;
`;
