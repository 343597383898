import { t } from "../intl";

const { REACT_APP_PREFIX } = process.env;

interface ApiRequest {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  body?: any;
  headers?: Headers;
  errorTrKey?: string;
}

export function isJsonResponse<T>(value: Response | T, key: keyof T): value is T {
  return !!value && key in (value as T);
}

export class ApiService {
  protected apiPrefix = `/${REACT_APP_PREFIX}/api`;

  protected get = <T>(url: string, errorTrKey?: string) =>
    this.doFetch<T>({
      url: `${this.apiPrefix}/${url}`,
      method: "GET",
      errorTrKey,
    });

  protected post = <T>(url: string, body: any, headers?: Headers, errorTrKey?: string) =>
    this.doFetch<T>({
      url: `${this.apiPrefix}/${url}`,
      method: "POST",
      body,
      headers,
      errorTrKey,
    });

  protected put = <T>(url: string, body: any, errorTrKey?: string) =>
    this.doFetch<T>({
      url: `${this.apiPrefix}/${url}`,
      method: "PUT",
      body,
      errorTrKey,
    });

  protected delete = <T>(url: string, body?: any, errorTrKey?: string) =>
    this.doFetch<T>({
      url: `${this.apiPrefix}/${url}`,
      method: "DELETE",
      body,
      errorTrKey,
    });

  private doFetch = async <T>(apiRequest: ApiRequest) => {
    try {
      const response = await fetch(apiRequest.url, {
        method: apiRequest.method,
        body: apiRequest.body,
        headers: apiRequest.headers || new Headers({ "Content-Type": "application/json" }),
        credentials: "same-origin",
      });

      if (!response.ok) throw response;

      if (response.headers.get("Content-Type")?.includes("application/json")) {
        return (await response.json()) as T;
      }

      return response;
    } catch (error) {
      throw new Error(t(apiRequest.errorTrKey || "errorPage.unexpectedError.desc"));
    }
  };
}
