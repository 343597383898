import { createGlobalStyle } from "styled-components/macro";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.grayDark};
    font-family: "Nunito Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    
    * {
      box-sizing: border-box;
    }
  }
  *:not([class^="pru-iconset_"],[class*=" pru-iconset_"]) {
      &, &:before, &:after {
        box-sizing: border-box;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;
        transition-property: color, background-color, padding, border-color, border-width, box-shadow, stroke, fill;
      }
    }
`;
