import { IUploadResponse } from "../models/uploadResponse";

interface ISummaryConfigField {
  id: keyof IUploadResponse["form"] | "files";
  labelTrKey: string;
}

export const summaryConfigFields: ISummaryConfigField[] = [
  {
    id: "firstname",
    labelTrKey: "summary.userData.firstName.label",
  },
  {
    id: "lastname",
    labelTrKey: "summary.userData.lastName.label",
  },
  {
    id: "policyNo",
    labelTrKey: "summary.userData.policyNo.label",
  },
  {
    id: "topic",
    labelTrKey: "summary.userData.topic.label",
  },
  {
    id: "category",
    labelTrKey: "summary.userData.category.label",
  },
  {
    id: "description",
    labelTrKey: "summary.userData.description.label",
  },
  {
    id: "files",
    labelTrKey: "summary.userData.documents.label",
  },
];
