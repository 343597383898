import styled from "styled-components/macro";

export const TypoH1 = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
`;

export const TypoBodyRegular = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const TypoCaptionBold = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
`;

export const TypoSubheader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const TypoSmallRegular = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const TypoBodyBold = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const TypoSmallBold = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const TypoBodyLineRegular = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
