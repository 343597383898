import { IValidationRule } from "../constants/ConfigTypes";
import { EFormFieldType, TFormField } from "../models/formField";
import { basicRegexValidator, filesValidator, requiredValidator } from "../utils/validators";

const documentsBasicFormConfig: TFormField[] = [
  {
    id: "firstname",
    type: EFormFieldType.input,
    headerTrKey: "documentsForm.form.firstName.title",
    allowedSignsRegex: "^.{0,50}$",
    validationKey: "firstname",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongFormat"),
  },
  {
    id: "lastname",
    type: EFormFieldType.input,
    headerTrKey: "documentsForm.form.lastName.title",
    allowedSignsRegex: "^.{0,50}$",
    validationKey: "lastname",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongFormat"),
  },
  {
    id: "policyNo",
    type: EFormFieldType.input,
    headerTrKey: "documentsForm.form.policyNo.title",
    allowedSignsRegex: "^[0-9]{0,8}$",
    validationKey: "policyNo",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongFormat"),
  },
  {
    id: "topic",
    type: EFormFieldType.dictionary,
    headerTrKey: "documentsForm.form.topic.title",
    dictionary: "topics",
    validationKey: "topic",
    validator: (value: string, config: IValidationRule) =>
      requiredValidator(value, config, "validationResults.message.emptyField"),
  },
  {
    id: "category",
    type: EFormFieldType.dictionary,
    headerTrKey: "documentsForm.form.category.title",
    dictionary: "categories",
    validationKey: "category",
    validator: (value: string, config: IValidationRule) =>
      requiredValidator(value, config, "validationResults.message.emptyField"),
  },
  {
    id: "description",
    type: EFormFieldType.textarea,
    headerTrKey: "documentsForm.form.description.title",
    validationKey: "description",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongFormat"),
  },
  {
    id: "files",
    type: EFormFieldType.files,
    headerTrKey: "documentsForm.form.documents.title",
    validationKey: "files",
    validator: (value: any, config: IValidationRule) => filesValidator(value, config),
  },
];

export const documentsPhoneFormConfig: TFormField[] = [
  {
    id: "code",
    type: EFormFieldType.oneTimeCode,
    headerTrKey: "documentsForm.form.code.phone.title",
    allowedSignsRegex: "^[0-9]{0,6}$",
    validationKey: "code",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongFormat"),
  },
  ...documentsBasicFormConfig,
];

export const documentsEmailFormConfig: TFormField[] = [
  {
    id: "code",
    type: EFormFieldType.oneTimeCode,
    headerTrKey: "documentsForm.form.code.email.title",
    allowedSignsRegex: "^[0-9]{0,6}$",
    validationKey: "code",
    validator: (value: string, config: IValidationRule) =>
      basicRegexValidator(value, config, "validationResults.message.wrongFormat"),
  },
  ...documentsBasicFormConfig,
];
