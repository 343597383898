import { Input, InputProps } from "./Input";
import React from "react";
import styled from "styled-components/macro";
import { LabelQuestion, LabelQuestionProps } from "./LabelQuestion";

export interface InputFormProps {
  inputProps: InputProps;
  labelProps?: LabelQuestionProps;
}

export const InputFormElement = ({ inputProps, labelProps }: InputFormProps) => {
  return (
    <Wrapper>
      {labelProps && <LabelQuestion {...labelProps} />}
      <Input {...inputProps} />
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: block;
`;
